export default class SegmentStatisticUtil {
  static format(usersCount?: number | null, usersShare?: number | null) {
    if (usersCount == null || usersShare == null) {
      return ``;
    }

    const numberLocale = "en-US";
    const count = usersCount.toLocaleString(numberLocale);
    const shareInPercents = usersShare * 100;
    const share =
      shareInPercents > 0 && shareInPercents < 0.01
        ? "< 0.01"
        : shareInPercents.toLocaleString(numberLocale, {
            maximumFractionDigits: 2,
          });
    return `${count} (${share}%)`;
  }
}
