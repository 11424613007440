




































































































































































































































































import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import TextPreview from "@/shared/components/TextPreview.vue";
import SegmentModel, {
  SegmentStatus,
  SegmentType,
  SEGMENT_TYPE_CONVERTIONS,
} from "@/segments/models/SegmentModel";
import { ConvertSegmentRequestModel } from "@/segments/models/SegmentRequestModel";
import { FilterId, AppSection } from "@/shared/models";
import DateUtil from "@/shared/utils/DateUtil";
import SegmentStatisticUtil from "@/shared/utils/SegmentStatisticUtil";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component({
  components: {
    TextPreview,
  },
})
export default class SegmentsView extends mixins(AppSectionAccessMixin) {
  readonly duplicatableTypes = [
    SegmentType.CUSTOM_STATIC,
    SegmentType.CUSTOM_DYNAMIC,
  ];
  readonly recalculatableTypes = [
    SegmentType.CUSTOM_STATIC,
    SegmentType.CUSTOM_DYNAMIC,
    SegmentType.FUNNEL,
  ];
  readonly recalculatableStatuses = [
    SegmentStatus.CREATED,
    SegmentStatus.ACTIVE,
    SegmentStatus.RECALCULATION_FAILED,
    SegmentStatus.STATISTICS_RECALCULATION_FAILED,
  ];
  readonly oneTimeRecalculatableTypes = [SegmentType.PROVIDED];
  readonly oneTimeRecalculatableStatuses = [
    SegmentStatus.CREATED,
    SegmentStatus.RECALCULATION_FAILED,
  ];
  deleteDialog: Record<string, any> = {
    segment: undefined,
    value: false,
  };
  recalculateDialog: Record<string, any> = {
    segment: undefined,
    value: false,
  };
  search = "";
  showOnlyMy = false;

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get title(): string {
    return this.$lang(`menu.segments.${this.appSection.toLowerCase()}`);
  }

  get headers(): Array<Record<string, any>> {
    return [
      ...(this.isABTestSegments
        ? [
            {
              text: this.$lang("segment.group"),
              value: "groupId",
            },
          ]
        : []),
      {
        text: this.$lang("segment.name"),
        value: "name",
      },
      ...(!this.isABTestSegments
        ? [
            {
              text: this.$lang("segment.description"),
              value: "description",
              width: "14%",
            },
          ]
        : []),
      {
        text: this.$lang("segment.status.title"),
        value: "status",
        width: "8%",
      },
      {
        text: this.$lang("segment.users"),
        value: "usersCount",
        width: "10%",
      },
      ...(this.isABTestSegments
        ? [
            {
              text: this.$lang("segment.relativeShare"),
              value: "relativeShare",
              width: "10%",
            },
          ]
        : []),
      {
        text: this.$lang("segment.owner"),
        value: "ownerName",
        width: "8%",
      },
      ...(!this.isABTestSegments
        ? [
            {
              text: this.$lang("accessType.title"),
              value: "accessType",
              width: "8%",
            },
          ]
        : []),
      {
        text: this.$lang("segment.lastRecalculation"),
        value: "lastSuccessfulRecalculationAt",
        width: "11%",
      },
      {
        text: this.$lang("segment.lastStatisticRecalculation"),
        value: "lastSuccessfulStatisticRecalculationAt",
        width: "11%",
      },
      {
        text: this.$lang("segment.actions.title"),
        value: "action",
        align: "end",
        width: "9%",
        sortable: false,
      },
    ];
  }

  get segments(): Array<SegmentModel> {
    const segments = this.$store.state.segmentStore.segments.filter(
      (segment: SegmentModel) => {
        return segment.type === this.segmentType;
      }
    );

    if (this.showOnlyMy) {
      return segments.filter(
        (segment: SegmentModel) => segment.ownerId === this.currentUser.username
      );
    }

    return segments.sort(
      (a: SegmentModel, b: SegmentModel) =>
        new Date(b.createdAt as string).getTime() -
        new Date(a.createdAt as string).getTime()
    );
  }

  get segmentGroupUsers(): Record<string, number> | null {
    if (!this.isABTestSegments) {
      return null;
    }

    return this.segments.reduce(
      (result: Record<string, number>, segment: SegmentModel) => {
        if (!segment.groupId || segment.usersCount === undefined) {
          return result;
        }

        if (result[segment.groupId] !== undefined) {
          result[segment.groupId] += segment.usersCount;
        } else {
          result[segment.groupId] = segment.usersCount;
        }

        return result;
      },
      {}
    );
  }

  get appSection(): AppSection {
    return this.$route.name as AppSection;
  }

  get segmentType(): SegmentType | undefined {
    return new Map([
      [AppSection.FUNNEL_SEGMENTS, SegmentType.FUNNEL],
      [AppSection.AB_TEST_SEGMENTS, SegmentType.AB_TEST],
      [AppSection.DIVIDER_SEGMENTS, SegmentType.DIVIDER],
      [AppSection.PROVIDED_SEGMENTS, SegmentType.PROVIDED],
      [AppSection.CUSTOM_STATIC_SEGMENTS, SegmentType.CUSTOM_STATIC],
      [AppSection.CUSTOM_DYNAMIC_SEGMENTS, SegmentType.CUSTOM_DYNAMIC],
      [AppSection.EXTERNAL_TEST_SEGMENTS, SegmentType.EXTERNAL_AB_TEST],
    ]).get(this.appSection);
  }

  get isABTestSegments(): boolean {
    return this.segmentType === SegmentType.AB_TEST;
  }

  get isAddButtonVisible(): boolean {
    return (
      this.hasCreateAccess &&
      [...this.duplicatableTypes, SegmentType.PROVIDED].includes(
        this.segmentType as SegmentType
      )
    );
  }

  get isLoading(): boolean {
    return this.$store.state.segmentStore.loadingSegments;
  }

  @Watch("applicationId", { immediate: true })
  private watchApplicationId(applicationId: string) {
    this.$store.dispatch("fetchSegments", applicationId);
  }

  created() {
    document.title = this.$lang("documentTitle", this.$lang("segment.title"));
  }

  convertSegment(segment: SegmentModel) {
    const newType = SEGMENT_TYPE_CONVERTIONS.get(segment.type);
    if (segment.id && newType) {
      this.$store.dispatch(
        "convertSegment",
        new ConvertSegmentRequestModel(
          segment.id,
          segment.applicationId,
          newType
        )
      );
    }
  }

  formatDateTime(date?: string): string | undefined {
    return date ? DateUtil.formatDateTime(date) : undefined;
  }

  formatStatistic(usersCount: number, usersShare: number): string {
    return SegmentStatisticUtil.format(usersCount, usersShare);
  }

  isRecalculatable(segment: SegmentModel): boolean {
    return !!(
      segment.status &&
      ((this.recalculatableTypes.includes(segment.type) &&
        this.recalculatableStatuses.includes(segment.status)) ||
        (this.oneTimeRecalculatableTypes.includes(segment.type) &&
          this.oneTimeRecalculatableStatuses.includes(segment.status)))
    );
  }

  isConvertable(segment: SegmentModel): boolean {
    return (
      segment.editable &&
      segment.hasEditAccess &&
      Object.values(SegmentType)
        .filter((type) => SEGMENT_TYPE_CONVERTIONS.get(type))
        .includes(segment.type)
    );
  }

  getRelativeShare(segment: SegmentModel): string {
    if (
      !segment.groupId ||
      !this.segmentGroupUsers ||
      !this.segmentGroupUsers[segment.groupId] ||
      !segment.usersCount
    ) {
      return "0%";
    }

    return (
      (
        (segment.usersCount * 100) /
        this.segmentGroupUsers[segment.groupId]
      ).toLocaleString(this.$vuetify.lang.current, {
        maximumFractionDigits: 2,
      }) + "%"
    );
  }

  getConvertHint(segment: SegmentModel): string | undefined {
    const newType = SEGMENT_TYPE_CONVERTIONS.get(segment.type);
    return newType
      ? this.$lang(`segment.actions.convert.hint.${newType.toLowerCase()}`)
      : "";
  }

  confirmRecalculate(segment: SegmentModel) {
    if (
      segment.filter &&
      segment.filter.some((it) =>
        [
          FilterId.SESSION_NUMBER,
          FilterId.EVENT,
          FilterId.AD_REVENUE,
          FilterId.IAP_REVENUE,
        ].includes(it.id)
      ) &&
      !this.recalculateDialog.value
    ) {
      this.recalculateDialog = {
        value: true,
        segment,
      };
    } else {
      this.$store.dispatch("recalculateSegment", segment);
    }
  }

  confirmDelete(segment: SegmentModel) {
    this.deleteDialog = { value: true, segment };
  }

  async recalculateSegment() {
    await this.$store.dispatch(
      "recalculateSegment",
      this.recalculateDialog.segment
    );
    this.recalculateDialog = {
      segment: undefined,
      value: false,
    };
  }

  async deleteSegment() {
    await this.$store.dispatch("deleteSegment", this.deleteDialog.segment);
    this.deleteDialog = { segment: undefined, value: false };
  }
}
